//
// Wrapper
//

// General mode
.app-wrapper {
    display: flex;
}

// Desktop mode
@include media-breakpoint-up(lg) {
    // Integration
    .app-wrapper {
        transition: $app-wrapper-transition;
        
        // Header
        [data-kt-app-header-sticky="on"] & {
            margin-top: var(--#{$prefix}app-header-height-actual);
        }

        [data-kt-app-header-fixed="true"] & {
            margin-top: var(--#{$prefix}app-header-height);
        }

        // Sidebar
        [data-kt-app-sidebar-fixed="true"] & {
            margin-left: calc(
                var(--#{$prefix}app-sidebar-width) + 
                var(--#{$prefix}app-sidebar-gap-start, 0px) + 
                var(--#{$prefix}app-sidebar-gap-end, 0px)
            );
        }

        // Sidebar Panel
        [data-kt-app-sidebar-panel-fixed="true"] & {
            margin-left: calc(
                var(--#{$prefix}app-sidebar-width) + 
                var(--#{$prefix}app-sidebar-gap-start, 0px) + 
                var(--#{$prefix}app-sidebar-gap-end, 0px) +
                var(--#{$prefix}app-sidebar-panel-width) + 
                var(--#{$prefix}app-sidebar-panel-gap-start, 0px) + 
                var(--#{$prefix}app-sidebar-panel-gap-end, 0px)
            );
        }



        // Footer
        [data-kt-app-footer-fixed="true"] & {
            margin-bottom: var(--#{$prefix}app-footer-height);
        }  
    }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
    // Integration
    .app-wrapper {
        transition: $app-wrapper-transition;
        
        // Header
        [data-kt-app-header-sticky="on"] & {
            margin-top: var(--#{$prefix}app-header-height-actual);
        }

        [data-kt-app-header-fixed-mobile="true"] & {
            margin-top: var(--#{$prefix}app-header-height);
        }

        // Footer
        [data-kt-app-footer-fixed-mobile="true"] & {
            margin-bottom: var(--#{$prefix}app-footer-height);
        }  
    }
}