//
// Dark Header(used by Dark Header layout only)
//

[data-kt-app-layout="dark-header"] {
    .app-header {
        background-color: $app-header-dark-bg-color;
        border-bottom: 0 !important;

        .btn-custom {
            @include button-custom-variant(
                $color:#B5B5C3, 
                $icon-color: #B5B5C3, 
                $border-color: null, 
                $bg-color: null, 
                $color-active: #B5B5C3, 
                $icon-color-active: var(--#{$prefix}primary), 
                $border-color-active: null, 
                $bg-color-active: rgba(63, 66, 84, 0.35)
            );
        }
    }
}

