@import "node_modules/@swimlane/ngx-datatable/index.css";
//@import "/node_modules/@swimlane/ngx-datatable/themes/bootstrap.scss";
@import "node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "src/assets/sass/custom/_switch.scss";
.ngx-datatable .datatable-footer .datatable-pager .pager,
.ngx-datatable .datatable-footer .datatable-pager .pager li {
  padding: 10px !important;
  margin: 10px;

}

// Define your color palette
$primary-color: $primary;
$secondary-color: $secondary;
$background-color: $primary-light;
$border-color: $primary-light;
$text-color: $primary;
$header-background-color: $primary-dark;
$header-text-color: #f0f0f0;
$row-hover-color: $secondary;
$footer-background-color: $primary-light;
$footer-text-color: $primary;
$active-page-btn-bg: $primary-light;
$active-page-btn-color: #f0f0f0;
$base-header-font-size:1rem;


.system-result-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  /* Space between the indicator and the text */
}

.ngx-datatable.bootstrap {
  box-shadow: none;
  font-size: 18px;
  color: $text-color;

  .detail-row-container {
    padding: 20px;

  }
  
  .detail-row-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .detail-row-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px; /* This will ensure spacing between items within the column */
  }
  
  .detail-row-group {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .detail-group-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .detail-row-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .detail-row-label {
    min-width: 100px;
    font-weight: bold;
    color: #555;
  }
  
  .detail-row-value {
    flex: 1;
    color: #777;
  }

  .case-details-button,
  .assign-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    /* Example blue color */
    color: white;
    cursor: pointer;
    font-weight: bold;
  }

  .case-assignment-selector {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
  }

  /* Ensure buttons and selector have the same height */
  .case-details-button,
  .assign-button,
  .case-assignment-selector {
    height: 40px;
    /* Example fixed height */
  }

  /* Add some spacing between the selector and the ASSIGN button */
  .case-assignment-selector {
    margin-right: 10px;
  }

  .datatable-header {
    background-color: $header-background-color;
    color: $header-text-color;
    height: 60px;
    line-height: 60px;
    font-weight: 500;
    align-content: center;
    font-size: $base-header-font-size; // Set base font size
    border-radius: 5px;
    .datatable-header-cell {
      white-space: wrap;
      align-content: center;
    }
  
    // Responsive font size adjustments
    // @media only screen and (max-width: 1200px) {
    //   font-size: calc(#{$base-header-font-size} * 0.9); // Slightly smaller font size
    // }
    // @media only screen and (max-width: 992px) {
    //   font-size: calc(#{$base-header-font-size} * 0.8); // Further reduce font size
    // }
    // @media only screen and (max-width: 768px) {
    //   font-size: calc(#{$base-header-font-size} * 0.7); // Even smaller font size
    // }
    // @media only screen and (max-width: 576px) {
    //   font-size: calc(#{$base-header-font-size} * 0.7); // Smallest font size for mobile
    // }


    // Responsive font size adjustments - Covariance
    @media only screen and (max-width: 1200px) {
      font-size: $base-header-font-size; // Slightly smaller font size
    }
    @media only screen and (max-width: 992px) {
      font-size: $base-header-font-size; // Further reduce font size
    }
    @media only screen and (max-width: 768px) {
      font-size: $base-header-font-size; // Even smaller font size
    }
    @media only screen and (max-width: 576px) {
      font-size: $base-header-font-size; // Smallest font size for mobile
    }
  }

  .datatable-body {
    background-color: $background-color;
    .datatable-row-wrapper {
      border: 1px solid #ddd;
      margin-bottom: 5px;
      background-color: white;
      border-radius: 5px;
    }
    .datatable-body-row {
      border-top: 1px solid $border-color;
      border-radius: 5px;
     
      &.datatable-row-even {
       // background-color: $row-hover-color;
      }

      &.active,
      &:hover {
        background-color: $row-hover-color;
        color: $text-color;
      }

      .datatable-body-cell {
        align-content: center;
        //padding: 10px 15px;
        //  border-right: 1px solid $border-color;
        a {
          text-decoration: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .datatable-footer {
    background: $footer-background-color;
    color: $footer-text-color;
    padding: 10px 20px;
    .datatable-pager ul {
      li {
        border: 1px solid $border-color;
        border-radius: 4px;
        transition: background-color 0.3s;
        &:not(.disabled) {
          &:hover {
            cursor: pointer;
            a {
              color: $active-page-btn-color !important;
              background-color: $primary-color;
              border-radius: 50%;
            }
          }
          &.active {
            a {
              color: $active-page-btn-color !important;
              background-color: $primary-color;
              border-radius: 50%;
            }
          }
        }
    
        a {
          color: $primary-color;
          text-decoration: none !important;
          text-align: center;
          line-height: 30px;
          width:30px;
          height:30px;
          // The rest of your styles for 'a' element
        }
      }
    }
  }
}