.ngx-datatable {
  display: block;
  overflow: hidden;
  justify-content: center;
  position: relative;
  transform: translate3d(0, 0, 0);
  /**
   * Vertical Scrolling Adjustments
   */
  /**
   * Horizontal Scrolling Adjustments
   */
  /**
   * Fixed Header Height Adjustments
   */
  /**
   * Fixed row height adjustments
   */
  /**
   * Shared Styles
   */
  /**
   * Header Styles
   */
  /**
   * Body Styles
   */
  /**
   * Footer Styles
   */
}
.ngx-datatable [hidden] {
  display: none !important;
}
.ngx-datatable *,
.ngx-datatable *:before,
.ngx-datatable *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ngx-datatable.scroll-vertical .datatable-body {
  overflow-y: auto;
}
.ngx-datatable.scroll-vertical.virtualized .datatable-body .datatable-row-wrapper {
  position: absolute;
}
.ngx-datatable.scroll-horz .datatable-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
  white-space: nowrap;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ngx-datatable.fixed-row .datatable-scroll {
  white-space: nowrap;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
  white-space: nowrap;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ngx-datatable .datatable-body-row,
.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-header-inner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  -webkit-flex-flow: row;
  -moz-flex-flow: row;
  -ms-flex-flow: row;
  -o-flex-flow: row;
  flex-flow: row;
}
.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
  overflow-x: hidden;
  vertical-align: top;
  display: inline-block;
  line-height: 1.625;
}
.ngx-datatable .datatable-body-cell:focus,
.ngx-datatable .datatable-header-cell:focus {
  outline: none;
}
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-row-right,
.ngx-datatable .datatable-group-header {
  z-index: 9;
  position: sticky !important;
}
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-group-header {
  left: 0;
}
.ngx-datatable .datatable-row-right {
  right: 0;
}
.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-row-group {
  position: relative;
}
.ngx-datatable .datatable-header {
  display: block;
  overflow: hidden;
}
.ngx-datatable .datatable-header .datatable-header-inner {
  align-items: stretch;
  -webkit-align-items: stretch;
}
.ngx-datatable .datatable-header .datatable-header-cell {
  position: relative;
  display: inline-block;
}
.ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
  cursor: pointer;
}
.ngx-datatable .datatable-header .datatable-header-cell.longpress .datatable-header-cell-wrapper {
  cursor: move;
}
.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  line-height: 100%;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle--not-resizable {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  padding: 0 4px;
  visibility: hidden;
}
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
  cursor: ew-resize;
}
.ngx-datatable .datatable-header .datatable-header-cell.resizeable:hover .resize-handle {
  visibility: visible;
}
.ngx-datatable .datatable-header .datatable-header-cell:hover .resize-handle--not-resizable {
  visibility: visible;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker {
  position: absolute;
  top: 0;
  bottom: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromLeft {
  right: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromRight {
  left: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  height: inherit;
}
.ngx-datatable .datatable-body {
  position: relative;
  z-index: 10;
  display: block;
  overflow: hidden;
}
.ngx-datatable .datatable-body .datatable-scroll {
  display: inline-block;
}
.ngx-datatable .datatable-body .datatable-row-detail {
  overflow-y: hidden;
}
.ngx-datatable .datatable-body .datatable-row-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.ngx-datatable .datatable-body .datatable-body-row {
  outline: none;
}
.ngx-datatable .datatable-body .datatable-body-row > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.ngx-datatable .datatable-footer {
  display: block;
  width: 100%;
  overflow: auto;
}
.ngx-datatable .datatable-footer .datatable-footer-inner {
  display: flex;
  align-items: center;
  width: 100%;
}
.ngx-datatable .datatable-footer .selected-count .page-count {
  flex: 1 1 40%;
}
.ngx-datatable .datatable-footer .selected-count .datatable-pager {
  flex: 1 1 60%;
}
.ngx-datatable .datatable-footer .page-count {
  flex: 1 1 20%;
}
.ngx-datatable .datatable-footer .datatable-pager {
  flex: 1 1 80%;
  text-align: right;
}
.ngx-datatable .datatable-footer .datatable-pager .pager,
.ngx-datatable .datatable-footer .datatable-pager .pager li {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li,
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  outline: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  cursor: pointer;
  display: inline-block;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
  cursor: not-allowed;
}
