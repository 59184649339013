//
// Variables
//

// Reboot

// Gradient colors
$primary-gradient:            $primary-light-dark !default;
$primary-gradient-state:      $app-bg-color-dark !default;

$secondary-gradient:          #747b8a !default;
$secondary-gradient-state:    #495361 !default;

$info-gradient:               #1A73E8 !default;
$info-gradient-state:         #D8FFDB !default;

$success-gradient:            #4CAF50 !default;
$success-gradient-state:      #66BB6A !default;

$danger-gradient:             #F44335 !default;
$danger-gradient-state:       #EF5350 !default;

$warning-gradient:            #fb8c00 !default;
$warning-gradient-state:      #FFA726 !default;

$dark-gradient:               #212529 !default;
$dark-gradient-state:         #495057 !default;

$light-gradient:              #495057 !default;
$light-gradient-state:        #6c757d !default;

$dark-gradient-dark:          #343a40 !default;
$dark-gradient-state-dark:    #495057 !default;


$app-sidebar-base-bg-color-dark: $primary-gradient;
$app-sidebar-dark-bg-color: $primary-gradient;

$app-bg-color: $primary-light;
$app-bg-color-dark: $primary-light-dark;
$app-blank-bg-color: $white;
$app-blank-bg-color-dark: $app-bg-color-dark;

// General
$app-general-root-font-size-desktop: 13px;
$app-general-root-font-size-tablet: 12px;
$app-general-root-font-size-mobile: 12px;

// Header base
$app-header-base-height: 70px;
$app-header-base-height-mobile: 60px;
$app-header-base-bg-color: $primary-light;
$app-header-base-bg-color-dark: $primary;
$app-header-base-bg-color-mobile: $app-header-base-bg-color;
$app-header-base-bg-color-mobile-dark: $app-header-base-bg-color-dark;
$app-header-base-box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05);
$app-header-base-box-shadow-dark: none;
$app-header-base-menu-link-bg-color-active: $menu-link-bg-color-active;
$app-header-base-menu-link-bg-color-active-dark: #2A2A3C;

// Header light
$app-header-light-separator-color: #E4E6EF;
$app-header-light-separator-color-dark: $border-color-dark;

// Header dark
$app-header-dark-bg-color: $primary-light;
$app-header-dark-separator-color: #282a3d;
$app-header-dark-scrollbar-color: #3b3b64;
$app-header-dark-scrollbar-color-hover: lighten($app-header-dark-scrollbar-color, 3%);

// Sidebar base
$app-sidebar-base-width: 265px;
$app-sidebar-base-width-mobile: 250px; 

$app-sidebar-base-toggle-btn-box-shadow: 0px 0px 10px rgba(113, 121, 136, 0.1);
$app-sidebar-base-toggle-btn-box-shadow-dark: none;
$app-sidebar-base-toggle-btn-bg-color: $body-bg;
$app-sidebar-base-toggle-btn-bg-color-dark: $app-header-base-menu-link-bg-color-active-dark;

// Sidebar minimize
$app-sidebar-minimize-width: 65px;

// Sidebar light
$app-sidebar-light-bg-color: $body-bg;
$app-sidebar-light-bg-color-dark: $primary-dark;

$app-sidebar-light-box-shadow: 0 0 28px 0 rgba(82,63,105,.05);
$app-sidebar-light-box-shadow-dark: none;

$app-sidebar-light-separator-color: $app-header-light-separator-color;
$app-sidebar-light-separator-color-dark: $app-header-light-separator-color-dark;

$app-sidebar-light-scrollbar-color: $gray-200;
$app-sidebar-light-scrollbar-color-dark: $gray-200-dark;
$app-sidebar-light-scrollbar-color-hover: $gray-200;
$app-sidebar-light-scrollbar-color-hover-dark: $gray-200-dark;
$app-sidebar-light-menu-heading-color: #B5B5C3;
$app-sidebar-light-menu-heading-color-dark: $gray-500-dark;
$app-sidebar-light-menu-link-bg-color-active: #F4F6FA;
$app-sidebar-light-menu-link-bg-color-active-dark: #2A2A3C;
$app-sidebar-light-header-menu-link-bg-color-active: #EAEEF2;
$app-sidebar-light-header-menu-link-bg-color-active-dark: $gray-100-dark;
    
// Sidebar dark
$app-sidebar-dark-bg-color:$primary-gradient;
$app-sidebar-dark-separator-color: $primary-active;
$app-sidebar-dark-scrollbar-color: $gray-300-dark;
$app-sidebar-dark-scrollbar-color-hover: $gray-300-dark;
$app-sidebar-dark-menu-heading-color: $primary-light;
$app-sidebar-dark-menu-link-bg-color-active: $primary-active;
$app-sidebar-base-toggle-btn-box-shadow-dark: none;
$app-sidebar-base-toggle-btn-bg-color: $app-sidebar-dark-bg-color;
$app-sidebar-base-toggle-btn-bg-color-dark: $app-header-base-menu-link-bg-color-active-dark;

// Footer
$app-footer-height: 60px;
$app-footer-height-mobile: auto;
$app-footer-bg-color: $body-bg;
$app-footer-bg-color-dark: $primary-dark;
$app-footer-box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05);
$app-footer-box-shadow-dark: none;

// Scrolltop
$scrolltop-bottom: 43px;
$scrolltop-bottom-mobile: 23px;
$scrolltop-end: 7px;
$scrolltop-end-mobile: 5px;

// Layout builder toggle(used for Demo purposes only)
$app-layout-builder-toggle-end: 50px;
$app-layout-builder-toggle-end-mobile: 40px;
$app-layout-builder-toggle-bottom: 40px;
$app-layout-builder-toggle-bottom-mobile: 20px;

// Keenthemes menu component   
$menu-icon-size: 2rem ;
$menu-icon-space: 2rem;
$menu-bullet-size: 1.25rem;
$menu-bullet-space: 0.5rem ;
$menu-badge-space: 0.5rem ;
$menu-arrow-size: 9px ;
$menu-arrow-space: 5px ;

// Assuming $form-check-input-width is defined somewhere in your variables
$form-check-input-width: 1rem; // Example value

