
// Desktop mode
@include media-breakpoint-up(lg) {
    [data-kt-app-layout="light-sidebar"]:not([data-kt-app-header-fixed="true"]) {
        .app-header {
            background-color: transparent;    
            box-shadow: none;
            border-bottom: 1px solid var(--#{$prefix}app-sidebar-light-separator-color);
        }
    }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
    [data-kt-app-layout="light-sidebar"]:not([data-kt-app-header-fixed-mobile="true"]) {
        .app-header {
            background-color: transparent;    
            box-shadow: none;
            border-bottom: 1px solid var(--#{$prefix}app-sidebar-light-separator-color);
        }
    }
}